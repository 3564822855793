$--theme-orange-2-color: #ffa305;
$--theme-orange-color: #d1990e;
$--theme-grey-color: #303030;
$--theme-grey-2-color: #545454;

:export {
  themeOrangeColor: $--theme-orange-color;
  themeOrange2Color: $--theme-orange-2-color;
  themeGreyColor: $--theme-grey-color;
  themeGrey2Color: $--theme-grey-2-color;
}
