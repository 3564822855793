@import "../../variables.module.scss";

.top-nav-bar {
  &.MuiPaper-root {
    background-color: $--theme-orange-color;
  }

  .navbar-logo-icon {
    width: 64px;
    height: 64px;
    display: flex;
    & > rect {
      fill: $--theme-orange-color;
    }
    & > g {
      fill: $--theme-grey-color;
    }
  }

  .navbar-menu-icon {
    color: $--theme-grey-color;
  }
}
